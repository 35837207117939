import axios from 'axios'
import config from '@/libs/config'
import {createHttp} from './request.js'

const userAxios = axios.create({
    baseURL: config.userApi,
    timeout: 6000,
})

const $http = createHttp(userAxios)
const post = $http.post

export const mobileRegister = (params) => {
    return post('/auth/signup/tel', params)
}
export const mobileLogin = (params) => {
    return post('/auth/code/signin', params)
}
export const accountLogin = (params) => {
    return post('/auth/signin', params)
}
export const getCaptcha = (params) => {
    return post('/auth/code/tel', params)
}
export const resetPassword = (params) => {
    return post('/auth//password/reset', params)
}
export const getUserInfo = (params) => {
    return post('/api/user/info', params)
}
export const setUserInfo = (params) => {
    return post('/api/user/save', params)
}