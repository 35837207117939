import {getCaptcha} from '@/libs/apis/userApi'
let timer = null
export const sendCaptcha = {
    data() {
        return {
            codeSeconds: 0,
        }
    },
    methods: {
		getCode (tel) {
			if(tel.length!=11){
				this.$toast.error(this.$t('Mobile is not Valid'))
				return
			}
			this.codeSeconds = 60
			timer = setInterval(()=>{
				this.codeSeconds --
				if(this.codeSeconds==0){
					clearInterval(timer)
				}
			},1000)
			getCaptcha( {
				tel: tel
			}).then(res=>{
				this.$toast(this.$t('Send success'))
			},err=>{
				this.$toast(err.message)
			})
		},
    },
}